import { v4 as generateGuid } from 'uuid';
import { getAccessToken } from './AuthUtils';
import { useTranslation } from 'react-i18next';

export const correlationId = generateGuid();

export const useHttpUtils = () => {

    const { i18n } = useTranslation();

    const fetchDataAuthenticated = async (url, method, body) => {

        let accessToken = await getAccessToken();

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
            'X-Correlation-ID': correlationId,
            'X-Language': i18n.language
        };

        return await fetch(url,
            {
                method: method,
                headers: headers,
                body: body
            });
    }

    const fetchBlobAuthenticated = async (url, method, body) => {

        let accessToken = await getAccessToken();

        let headers = {
            'Authorization': 'Bearer ' + accessToken,
            'X-Correlation-ID': correlationId
        };

        return await fetch(url,
            {
                method: method ??= 'GET',
                headers: headers,
                body: body,
                responseType: 'blob'
            });
    }

    return { fetchDataAuthenticated, fetchBlobAuthenticated }
}